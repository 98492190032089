.intro {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  .social {
    display: flex;
    justify-content: center;
    color: var(--color-base-text);
    padding: 3px 32px;
    border-radius: 50px;
  }
  .buttons {
    margin-top: 30px;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
  }
  h1, h2 {
    font-size: 44px;
    line-height: 1.1;
    font-weight: 600;
    margin-bottom: 20px;
    margin-top: 0;
    color: var(--color-base-text);
  }
  p {
    font-size: 26px;
    line-height: 1.3;
    font-weight: 300;
    color: var(--color-base-text-2);
  }
  img {
    border-radius: 20px;
    margin-bottom: 20px;
  }
  .avatar {
    border-radius: 50%;
    margin-bottom: 20px;
  }
}

.cta {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .buttons {
    display: flex;
    justify-content: center;
    .button {
      margin-right: 12px;
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
  .cta-footer {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    font-size: 16px;
    color: var(--color-base-text-3);
    font-family: var(--font-family-monospace);
    a {
      padding: 6px 12px;
    }
    .cta-phone {
      font-size: 16px;
      display: inline-block;
      color: var(--color-base-text-3);
      text-decoration-line: underline;
      text-decoration-style: dotted;
      text-decoration-color: var(--color-base-text-3);
      &:hover {
        text-decoration-line: underline;
        text-decoration-style: dotted;
        text-decoration-color: var(--color-base-text);
      }
    }
    .cta-email {
      display: inline-block;
      color: var(--color-base-text-3);
      text-decoration-line: underline;
      text-decoration-style: solid;
      text-decoration-color: var(--color-base-text-3);
      &:hover {
        text-decoration-line: underline;
        text-decoration-style: solid;
        text-decoration-color: var(--color-base-text);
      }
    }
  }
}

.status {
  white-space: nowrap;
  display: inline-block;
  background: var(--color-primary-bg);
  border-radius: var(--button-border-radius);
  font-weight: 500;
  letter-spacing: 0.025em;
  text-decoration: none;
  transition: all 0.15s ease;
  border: none;
  outline: none;
  margin: 0;
  padding: 14px 32px;
  background-color: #000;
  box-shadow: rgba(23, 22, 22, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  color: #fff;
  display: flex;
  align-items: center;
  &:hover {
    color: #fff;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
    text-decoration: none;
  }
  .status-light {
    width: 12px;
    height: 12px;
    display: inline-block;
    margin: 0;
    margin-right: 6px;
    color: #46e385;
  }
  .status-text {
    display: inline-block;
    margin: 0;
  }
}

.card {
  display: flex;
  flex-direction: column;
  border: none;
  height: 100%;
  padding: 0;
  background-color: var(--color-base-bg-2);
  border-radius: 26px;
  box-shadow: none;
  overflow: hidden;
  position: relative;
  .card-link {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;

    &:hover {
      & + .card-content {
        background: var(--color-base-bg-3);
        transition: background-color 300ms 0s;
      }
    }
  }
  .card-content {
    transition: background-color 300ms 0s;
    padding: 30px;
    display: flex;
    flex: 1;
    flex-direction: column;
  }
  .card-icon {
    position: relative;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    background-color: var(--color-base-bg-3);
    border-radius: 40px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      max-width: 24px;
      height: auto;
    }
  }
  .card-external {
    position: absolute;
    right: 30px;
    img {
      width: 16px;
      height: 16px;
    }
    html[data-mode="dark"] & {
      filter: brightness(0) invert(0.5);
    }
  }
  .card-title {
    h2 {
      font-family: var(--font-heading);
      font-size: 22px;
      line-height: 1.25;
      color: var(--color-base-text-1);
      font-weight: 600;
      margin: 0;
      margin-bottom: 8px;

      a {
        color: var(--color-base-text-1);
      }
    }
  }
  .card-description {
    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 1.4;
      color: var(--color-base-text-2);
    }
  }
  .card-date {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 0.8rem;
    font-weight: bold;
  }
  .card-categories {
    padding-top: 12px;
    display: inline-block;
    font-size: 0.8rem;
    font-family: var(--font-family-monospace);
    display: flex;
    .card-category {
      margin: 0 5px 5px 0;
      a {
        padding: 4px 12px;
        background-color: var(--color-base-bg-3);
        color: var(--color-base-bg-3-text);
        border-radius: 12px;
      }
    }
  }
}
