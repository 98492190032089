:root {
  --font-family-base: Inter, sans-serif;
  --font-family-heading: Inter, sans-serif;
  --font-family-monospace: 'Fira Mono', monospace;
  --font-family-logo: Inter, sans-serif;

  --color-primary-bg: #46e385;
  --color-primary-bg-2: #55f884;
  --color-primary-bg-3: #46e385;
  --color-primary-text: #114c29;
  --color-base-bg: #ffffff;
  --color-base-bg-2: #fcfcfc;
  --color-base-bg-3: #f2f2f2;
  --color-base-text: #2f2e2a;
  --color-base-text-2: #535247;
  --color-header-text:  #111827;
  
  --logo-text-font-size: 28px;
  --logo-text-font-weight: 500;

  --menu-font-family: Inter, sans-serif;
  --menu-font-size: ;
  --menu-text-transform: "Capitalize";
  --menu-font-weight: 400;

  --header-height: 60px;
  --section-padding: 80px;
  --card-border-radius: 5px;
  --button-border-radius: 5px;
}

html[data-bs-theme='dark'] {
  --color-primary-bg: #46e385;
  --color-primary-bg-2: #55f884;
  --color-primary-bg-3: #46e385;
  --color-primary-text: #114c29;
  --color-base-bg: #0b0b0b;
  --color-base-bg-2: #212123;
  --color-base-bg-3: #7e7e82;
  --color-base-text: #ffffff;
  --color-base-text-2: #bfc1c2;
  --color-logo-text: #ffffff;
  --color-header-text: #F4F4F5;
}

@import 'style';